import { createApp } from 'vue';

import router from './router';

import App from './App.vue';

window.app = createApp(App);
window.app.use(router);
window.app.mount('#app');

window.apiPath = `${window.location.origin}/php/`;
if (window.location.href.indexOf('localhost') >= 0) {
  window.apiPath = 'http://localhost:8082/';
}
