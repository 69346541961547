<template>
  <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
};
</script>

<style lang="scss">
  // scss-docs-start import-stack
  // Configuration
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  @import "~bootstrap/scss/utilities";

  // Layout & components
  @import "~bootstrap/scss/root";
  @import "~bootstrap/scss/reboot";
  @import "~bootstrap/scss/type";
  @import "~bootstrap/scss/images";
  @import "~bootstrap/scss/containers";
  @import "~bootstrap/scss/grid";
  @import "~bootstrap/scss/forms";
  @import "~bootstrap/scss/buttons";
  @import "~bootstrap/scss/button-group";
  @import "~bootstrap/scss/nav";
  @import "~bootstrap/scss/navbar";
  @import "~bootstrap/scss/transitions";
  @import "~bootstrap/scss/list-group";

  @import "~bootstrap/scss/helpers";

  @import "~bootstrap/scss/utilities/api";

  #app {
    font-family: Raleway,Lato,sans-serif;
    text-align: center;
  }

  body {
    background-color: #1f252b;
    color: #fff;
    background-image: url(assets/bg.svg);
  }

  .text-blue {
    color: rgba(28, 189, 239, 1);
  }

  a {
    text-decoration: none;
    color: #7fdbff;
    display: inline-block;
    outline: 0;
    transition: color .2s ease-in-out;
    &:hover {
      color: #61a2c1;
    }
  }

  .cookie-banner {
    a {
      color: black;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .text-yellow {
    color: #ffdc00;
  }

  .yellow {
    background-color: #ffdc00;
  }

  .item {
    background: #f3f3f3;
    border-radius: 3px;
    padding: 16px 16px;
  }

  .display-65 {
    font-size: 2rem;
  }

  .display-7 {
    font-size: 1.5rem;
  }

  .btn-blue {
    color: #fff;
    background-color: #1CBDEF;
    border-color: #1CBDEF;
    &:hover {
      color: #fff;
      background-color: #1a6791;
    }
  }

</style>

<style lang="scss">
  .card-img-overlay {
    background-color: rgba(0, 0, 0, .5);
  }
  .container-white {
    background-image: url(assets/bg_white.svg);
    background-size: contain;
    color: black;
  }

  .vh-65 {
    min-height: 66vh;
  }

  .vh-35 {
    min-height: 35vh;
  }
</style>
