import { createRouter, createWebHistory } from 'vue-router';
import { createApp } from 'vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: {
        title: 'Buner Laser Tag Arena | Home',
        metaDescription: '',
      },
    },
    {
      path: '/rezervari',
      name: 'rezervari',
      component: () => import('./views/Rezervari.vue'),
      meta: {
        title: 'Buner Laser Tag Arena | Rezervari Laser Tag',
        metaDescription: '',
      },
    },
    {
      path: '/album',
      name: 'album',
      component: () => import('./views/Album.vue'),
      meta: {
        title: 'Buner Laser Tag Arena | Album Foto',
        metaDescription: '',
      },
    },
    {
      path: '/covid',
      name: 'covid',
      component: () => import('./views/Covid.vue'),
      meta: {
        title: 'Buner Laser Tag Arena | Regulament Covid',
        metaDescription: '',
      },
    },
    {
      path: '/regulament',
      name: 'regulament',
      component: () => import('./views/Regulament.vue'),
      meta: {
        title: 'Buner Laser Tag Arena | Regulament Intern',
        metaDescription: '',
      },
    },
  ],
});

export default router;

const app = createApp({});

app.use(router);
window.vm = app.mount('#app');
